import React from 'react';
import PropTypes from 'prop-types';
import {Text, P2} from '../../style/Typography';
import {colors} from '../../style/theme';
import {Link} from 'gatsby';
import styled from 'styled-components';

const urlIsExternal = (url = '') => `${url}`.indexOf('://') !== -1;

const TextLink = (props) => {
  const {
    color,
    href,
    className,
    extraClasses,
    openInNewWindow,
    text,
    children,
  } = props;

  const isExternal = urlIsExternal(href);

  const componentProps = {
    as: isExternal ? 'a' : Link,
    color,
    className: `${extraClasses} ${className || ''}`,
    target: openInNewWindow ? '_blank' : '',
    size: '16px',
    bold: true,
  };

  componentProps[isExternal ? 'href' : 'to'] = href;

  return (
    <Text hoverline {...componentProps} onClick={props.onClick}>
      {text || children || 'Learn more'}
    </Text>
  );
};

const ArrowLinkContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 0;
  svg {
    margin-left: 9px;
    margin-top: 5px;
    height: 17px;
    .primary {
      fill: ${(props) => (props.color && colors[props.color]) || colors.green};
    }
    transition: transform 0.3s;
  }
  &:hover svg {
    transform: translateX(6px);
  }
`;

export const ArrowLink = ({className, ...props}) => (
  <ArrowLinkContainer color={props.color} className={className}>
    <P2>
      <TextLink {...props} />
    </P2>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 17">
      <path
        className="primary"
        d="M.8 7.3h25.7l-5.3-5.4L22.6.5l7.8 7.8-7.8 7.8-1.4-1.4 5.3-5.4H.8v-2z"
        fillRule="nonzero"
      />
    </svg>
  </ArrowLinkContainer>
);

TextLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  openInNewWindow: PropTypes.bool,
  color: PropTypes.oneOf([
    // todo: typescript this up
    'blue',
    'green',
    'gray',
    'red',
    'yellow',
    'lightYellow',
    'charcoal',
    'payneGrey',
    'lightCharcoal',
    'lightGray',
    'white',
    'lightGrayishMagenta',
    'darkGreen',
  ]),
  extraClasses: PropTypes.string,
};

TextLink.defaultProps = {
  color: 'green',
  extraClasses: '',
};

export default TextLink;
