import {CSSObject} from 'styled-components';
import '../utils/polyfill.Object.fromEntries';
import facepaint from 'facepaint';

export type themeColor =
  | 'blue'
  | 'green'
  | 'gray'
  | 'red'
  | 'yellow'
  | 'lightYellow'
  | 'charcoal'
  | 'payneGrey'
  | 'lightCharcoal'
  | 'lightGray'
  | 'white'
  | 'lightGrayishMagenta'
  | 'darkGreen'
  | 'transparent';

export type baseFont = 'h0' | 'h1' | 'h2' | 'h3' | 'p1' | 'p2' | 'ui1' | 'ui2';

type baseFontsObj = {
  [index in baseFont]: CSSObject;
};

interface typographyObject extends baseFontsObj {
  tablet: baseFontsObj;
  mobile: baseFontsObj;
  strong: CSSObject;
  emphasis: CSSObject;
  underline: CSSObject;
  hoverline: CSSObject;
}

export const px = {
  buttonBorderWidth: 3,
  inputBorderWidth: 2,
  minGutter: 25,
  minGutterMobile: 20,
  maxContentWidth: 1240,
  sectionPadding: 90,
  sectionPaddingMobile: 50,
};

export const constants = {
  buttonBorderWidth: `${px.buttonBorderWidth}px`,
  inputBorderWidth: `${px.inputBorderWidth}px`,
  underlineWidth: '0.125em', // 2px @ 16px
  minGutter: `${px.minGutter}px`,
  minGutterMobile: `${px.minGutterMobile}px`,
  maxContentWidth: `${px.maxContentWidth}px`,
  sectionPadding: `${px.sectionPadding}px`,
  sectionPaddingMobile: `${px.sectionPaddingMobile}px`,
};

// todo: create a types file to clean this stuff up
type colorObj = {
  [index in themeColor]: string;
};

export const colors: colorObj = {
  blue: '#39aac7',
  green: '#84b869',
  gray: '#a79d9c',
  red: '#d48d80',
  yellow: '#ffd04f',
  lightYellow: '#f6c142',
  charcoal: '#414042',
  payneGrey: '#404042',
  lightCharcoal: '#575b5b',
  lightGray: '#f7f7f8',
  white: '#ffffff',
  lightGrayishMagenta: '#f2f1f2',
  darkGreen: '#709f5a',
  transparent: 'transparent',
};

interface breakPxList {
  phone: string;
  tablet: string;
  small: string;
  medium: string;
  large: string;
}

export const breakPx = {
  phone: 500,
  tablet: 768,
  small: 1024,
  medium: 1280,
  large: 1440,
};

type widthQuery = {
  [key in keyof breakPxList]: string;
} & {
  (px: number): string;
};

const uptoFn = (px: number) => `max-width: ${px}px`;
const aboveFn = (px: number) => `min-width: ${px + 1}px`;

const uptoConstants = Object.fromEntries(
  Object.entries(breakPx).map(([k, px]) => [k, uptoFn(px)]),
);

const aboveConstants = Object.fromEntries(
  Object.entries(breakPx).map(([k, px]) => [k, aboveFn(px)]),
);

const upto = (Object.assign(uptoFn, uptoConstants) as unknown) as widthQuery;
const above = (Object.assign(aboveFn, aboveConstants) as unknown) as widthQuery;

export const query = {upto, above};

// facepaint but works better with our queires object by wrapping with media query
export const breaks = (queries: string[]) =>
  facepaint(queries.map((q) => `@media(${q})`));

export const fonts = {
  sofiaRegular: {
    fontFamily: 'sofia-pro, sans-serif',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  sofiaBold: {
    fontFamily: 'sofia-pro, sans-serif',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  bioSansBold: {
    fontFamily: 'bio-sans, sans-serif',
    fontWeight: 700,
  },
  notoSerifRegular: {
    fontFamily: 'noto-serif, serif;',
    fontWeight: 400,
  },
};

// todo: get rid of this in favor of just interpolating objects (woot!)
export const fontCSS = Object.keys(fonts).reduce((final, key) => {
  const def = fonts[key];
  final[key] = `
    font-family: ${def.fontFamily};
    font-weight: ${def.fontWeight};
    font-style: ${def.fontStyle || 'normal'};
  `;
  return final;
}, {});

// TODO, in this order
// H1: 60px, 63px, -1.73px (current h0)
// h2: 30px, 38px, -0.38px (revert to old)
// h4: 20px, 30px, 0 (current h3)
// h3: 25px, 28px,-0.32px (current h2)
// h0: kill it off
export const typography: typographyObject = {
  h0: {
    ...fonts.sofiaBold,
    fontSize: '60px',
    lineHeight: '63px',
    letterSpacing: '-1.73px',
  },
  h1: {
    ...fonts.sofiaBold,
    fontSize: '43px',
    lineHeight: '48px',
    letterSpacing: '-0.63px',
  },
  h2: {
    // Not used anywhere? should be 25px size, ~28px line height, -0.32px letter
    ...fonts.sofiaBold,
    fontSize: '25px', // was '30px',
    lineHeight: '28px', // was '38px',
    letterSpacing: '-0.32px', // was '-0.38px',
  },
  h3: {
    ...fonts.sofiaBold,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: 0,
  },
  p1: {
    ...fonts.sofiaRegular,
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: 'normal',
  },
  p2: {
    ...fonts.sofiaRegular,
    fontSize: '16px',
    lineHeight: '25px',
    letterSpacing: 'normal',
  },
  ui1: {
    ...fonts.sofiaBold,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  ui2: {
    ...fonts.sofiaBold,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: 'normal',
  },
  tablet: {
    h0: {
      ...fonts.sofiaBold,
      fontSize: '52px',
      lineHeight: '54px',
      letterSpacing: '-1.73px',
    },
    h1: {
      ...fonts.sofiaBold,
      fontSize: '32px',
      lineHeight: '34px',
      letterSpacing: '-0.5px',
    },
    h2: {
      ...fonts.sofiaBold,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.25px',
    },
    h3: {
      ...fonts.sofiaBold,
      fontSize: '16px',
      lineHeight: '25px',
      letterSpacing: 'normal',
    },
    p1: {
      ...fonts.sofiaRegular,
      fontSize: '16px',
      lineHeight: '25px',
      letterSpacing: 'normal',
    },
    p2: {
      ...fonts.sofiaRegular,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 'normal',
    },
    p3: {
      ...fonts.sofiaRegular,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 'normal',
    },
    ui1: {
      ...fonts.sofiaBold,
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
    ui2: {
      ...fonts.sofiaBold,
      fontSize: '14px',
      lineHeight: '14px',
      letterSpacing: 'normal',
    },
  },
  mobile: {
    h0: {
      // for mobile, same as h1
      ...fonts.sofiaBold,
      fontSize: '32px',
      lineHeight: '34px',
      letterSpacing: '-0.5px',
    },
    h1: {
      ...fonts.sofiaBold,
      fontSize: '32px',
      lineHeight: '34px',
      letterSpacing: '-0.5px',
    },
    h2: {
      ...fonts.sofiaBold,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.25px',
    },
    h3: {
      ...fonts.sofiaBold,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    p1: {
      ...fonts.sofiaRegular,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: 'normal',
    },
    p2: {
      ...fonts.sofiaRegular,
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: 'normal',
    },
    ui1: {
      ...fonts.sofiaBold,
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
    ui2: {
      ...fonts.sofiaBold,
      fontSize: '14px',
      lineHeight: '14px',
      letterSpacing: 'normal',
    },
  },
  strong: {
    fontWeight: 700,
  },
  emphasis: {
    fontStyle: 'italic',
  },
  underline: {
    borderBottom: `solid ${constants.underlineWidth}`,
    marginBottom: `-${constants.underlineWidth}`,
  },
  hoverline: {
    textDecoration: 'none',
    '&:hover': {
      borderBottom: `solid ${constants.underlineWidth}`,
      marginBottom: `-${constants.underlineWidth}`,
    },
  },
};

export default {
  breakPx,
  query,
  constants,
  colors,
  fonts,
  fontCSS,
  px,
  typography,
};
