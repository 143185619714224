import React from 'react';
import styled, {CSSObject, CSSProperties} from 'styled-components';
import theme, {themeColor, baseFont, colors, typography as tp} from './theme';

export interface typographyProps {
  color?: themeColor;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  hoverline?: boolean;
  base?: baseFont;
  responsive?: boolean;
  size?: number | string;
  sizes?: (number | string)[];
  queries?: string[];
}

interface typographyFn {
  (props: typographyProps): CSSObject;
}

const colorable: typographyFn = (props) => {
  if (props.color && colors[props.color]) {
    return {color: colors[props.color]};
  }
  return {};
};

const sizable: typographyFn = (props) => {
  if (props.size) {
    return {fontSize: props.size};
  }
  return {};
};

const boldable: typographyFn = (props) => (props.bold ? tp.strong : {});

const italicizable: typographyFn = (props) => (props.italic ? tp.emphasis : {});

const lineable: typographyFn = (props) => {
  let styleObj: CSSObject = {};
  if (props.underline) {
    styleObj = tp.underline;
    if (props.color && colors[props.color]) {
      styleObj.borderColor = colors[props.color];
    }
  }
  return styleObj;
};

const hoverlineable: typographyFn = ({hoverline}) =>
  hoverline ? tp.hoverline : {};

const basable: typographyFn = (props) => {
  if (props.base && tp[props.base]) {
    // return responsive stack if props.responsive
    return resp(tp.mobile[props.base], tp[props.base])(props);
  }
  return {};
};

const specials = {
  'sup,sup&,sub,sub&': {
    fontSize: '0.6em',
  },
};

// return responsive stack if props.responsive
// todo: better format for expressing and indexing responsive variations
const resp = (mobile: CSSObject, tablet: CSSObject, desktop: CSSObject) => (
  props: typographyProps,
) => {
  if (props.responsive) {
    return {
      ...mobile,
      [`@media(${theme.query.above.phone})`]: tablet,
      [`@media(${theme.query.above.large})`]: desktop,
    };
  }
  return desktop;
};

export const H0 = styled.h2(
  resp(tp.mobile.h0, tp.tablet.h0, tp.h0),
  colorable,
  lineable,
  specials,
);
export const H1 = styled.h2(
  resp(tp.mobile.h1, tp.tablet.h1, tp.h1),
  colorable,
  lineable,
  specials,
);
export const H2 = styled.h2(
  resp(tp.mobile.h2, tp.tablet.h2, tp.h2),
  colorable,
  lineable,
  specials,
);
export const H3 = styled.h2(
  resp(tp.mobile.h3, tp.tablet.h3, tp.h3),
  colorable,
  lineable,
  specials,
);
export const P1 = styled.p(
  resp(tp.mobile.p1, tp.tablet.p1, tp.p1),
  colorable,
  boldable,
  lineable,
  specials,
);
export const P2 = styled.p(
  resp(tp.mobile.p2, tp.tablet.p2, tp.p2),
  colorable,
  boldable,
  lineable,
  specials,
);

// utility component; style inline/any text with props
export const Text = styled.span(
  theme.fonts.sofiaRegular,
  specials,
  basable,
  colorable,
  boldable,
  italicizable, // IKR!?
  lineable,
  hoverlineable,
  specials,
  sizable,
);

// Same api as Text component, but builds a style object
export const buildTypograpy: typographyFn = (props) => ({
  ...theme.fonts.sofiaRegular,
  ...specials,
  ...basable(props),
  ...colorable(props),
  ...boldable(props),
  ...italicizable(props),
  ...lineable(props),
  ...hoverlineable(props),
  ...sizable(props),
});

export default {
  H1,
  H2,
  H3,
  P1,
  P2,
  Text,
  buildTypograpy,
};
